import {defaultCustomDialogParams} from "../../typings/CommonTypes";
import {Button, DialogTitle, Dialog, DialogActions, DialogContent, DialogContentText, Divider} from "@mui/material";
import {useTranslation} from "react-i18next";
import theme from "../../theme/theme";
import { useNavigate } from "react-router-dom";

export const CustomDialog = ({customDialogParams, setCustomDialogParams, customDialogOpen, setCustomDialogOpen})=> {

    const [t, i18n] = useTranslation('locale');

    let navigate = useNavigate();

    const handleDialogClose = () => {
        setCustomDialogParams(defaultCustomDialogParams);
        setCustomDialogOpen(false);
    }

    const handleConfirmation = () => {
        if(customDialogParams.onConfirm){
            customDialogParams.onConfirm();
        }
    }

    return(
        <Dialog
            fullWidth={true}
            maxWidth='xs'
            open={customDialogOpen}
            onClose={() => handleDialogClose()}
        >
            <DialogTitle id="alert-dialog-title">
                {customDialogParams.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {customDialogParams.description}
                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={() => handleDialogClose()}
                        style={{ textTransform: 'none', backgroundColor: 'transparent', color: theme.palette.primary.main }}>
                    {customDialogParams.buttonCancelText? customDialogParams.buttonCancelText : t('customDialog.doNothing')}
                </Button>
                <Button onClick={() => handleConfirmation()} style={{ textTransform: 'none' }} autoFocus variant={"contained"}>
                    {customDialogParams.buttonConfirmText? customDialogParams.buttonConfirmText : t('customDialog.proceed')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}


