import { Button, Divider, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { PageHeaderComponentProps } from '../../typings/CommonTypes';
import styles from './Common.module.scss';

export const PageHeaderComponent = ({ headerTitle, headerSubtitle, headerButton, headerDiscardButton, headerButtonDisabled }: PageHeaderComponentProps) => {
    const handleCallback = () => headerButton?.onClicked()
    const handleDiscardCallback = () => headerDiscardButton?.onClicked()

    return (
        <Box className={styles.pageTitle}>
            <Box className={styles.pageTitleContent} sx={{ pb: 3, paddingBottom: "12px" }}>
                <Box className={styles.pageTitleText}>
                    <Typography sx={{ fontWeight: 50 }} variant="h5" className={styles.pageTitleName}>{headerTitle}</Typography>
                    {headerSubtitle && <Typography variant="body1" className={styles.pageTitleDescription}>{headerSubtitle}</Typography>}
                </Box>
                <Box className={styles.pageTitleActions}>
                    {headerDiscardButton && (
                        <Button variant="outlined" size="medium" color="error" startIcon={<span className="material-symbols-rounded size-24">{headerDiscardButton.icon}</span>} onClick={handleDiscardCallback}>
                            {headerDiscardButton.name}
                        </Button>
                    )}

                    {headerButton &&
                        (
                            <Tooltip title={headerButtonDisabled && (headerButton.tooltip != undefined && headerButton.tooltip.length > 0) ? headerButton.tooltip : ""} placement="bottom">
                                <span>
                                    <Button
                                        style={{textTransform: "none"}}
                                        disabled={headerButtonDisabled ? headerButtonDisabled : false}
                                            variant="contained" size="medium" color="primary"
                                            startIcon={<span className="material-symbols-rounded size-24">{headerButton.icon}</span>} onClick={handleCallback}>
                                        {headerButton.name}
                                    </Button>
                                </span>
                            </Tooltip>
                        )}
                </Box>
            </Box>
            <Divider className={styles.pageTitleDivider} />
        </Box>
    )
}
