//styles
import '../../../styles/layout/campaign/Campaigns.scss';

import { Box, Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { setTabTitle } from '../../common/TabTitle';
import { useState } from 'react';
import {SnackbarOrigin} from "@mui/material/Snackbar";
import SnackbarComponent from "../../common/SnackbarComponent";
import { CampaignsGridList } from './CampaignsGridList';
import { PageHeaderComponent } from '../../common/PageHeaderComponent';
import { PageHeaderButtonProps } from '../../../typings/CommonTypes';

export interface SnackbarState extends SnackbarOrigin {
    open: boolean;
}

export const CampaignsView = ()=> {

    const [t, i18n] = useTranslation('locale');

    const pageHeaderSaveButton: PageHeaderButtonProps = {
        name: t("campaigns.createNewCampaign") as string,
        icon: "add",
        onClicked: () => handleSave()
    };

    async function handleSave() {
        window.location.assign(`/#/create`);
    }
      
    setTabTitle(t('campaigns.tabTitle'));

    const [snackbarState, setSnackbarState] = useState<SnackbarState>({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });

    return(
        <Box>
            <PageHeaderComponent
                    headerTitle={t('campaigns.tabTitle')}
                    headerButton={pageHeaderSaveButton}
            />
            <Grid container>
                <Grid item xs={12}>
                    <Card variant='outlined'>
                        <Box>
                            <CampaignsGridList showCampaignsGrid={true}/>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <SnackbarComponent setSnackbarState={setSnackbarState} snackbarState={snackbarState}/>
        </Box>
    )
}