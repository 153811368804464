import React from 'react';
import {Box, Card, CardContent, Divider, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import DetailsCardComponent from "./summary/DetailsCardComponent";
import DisplayGridList from './displaySelection/DisplayGridList';

const Summary = ({campaignDto, setCampaignDto, builderDto, setBuilderDto}) => {

    const [t, i18n] = useTranslation('locale');

    return (
        <Box mt={2}>
            <Card variant="outlined">
                <CardContent sx={{ padding: '16px' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        {t('summary.details')}
                    </Typography>
                </CardContent>
                <DetailsCardComponent
                    icon={AssignmentOutlinedIcon}
                    title={t('summary.campaignName')}
                    content={campaignDto.name}
                />
                <DetailsCardComponent
                    icon={CalendarTodayIcon}
                    title={t('summary.startEndDate')}
                    content={campaignDto.name}
                />
                <DetailsCardComponent
                    icon={WatchLaterIcon}
                    title={t('summary.startEndTime')}
                    content={campaignDto.name}
                />
            </Card>
            <Card sx={{marginTop: '24px'}}>
                <CardContent sx={{padding: '16px'}}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t('summary.displays')}</Typography>
                </CardContent>
                <Divider variant="fullWidth"/>
                <DisplayGridList
                    enableSelection={false}
                    displays={campaignDto.inventories}
                    isLoading={false}
                    campaignDto={campaignDto}
                    setCampaignDto={setCampaignDto}
                    builderDto={builderDto}
                    setBuilderDto={setBuilderDto} />
            </Card>
        </Box>
    );
};

export default Summary;