import {CardContent, Divider, Grid, Typography} from "@mui/material";
import React from "react";
import theme from "../../../../../../theme/theme";

const DetailsCardComponent = ({ icon: Icon, title, content }) => {
    return (
        <>
            <Divider variant="fullWidth" />
            <CardContent>
                <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item>
                        <Icon size={16} sx={{ color: theme.palette.primary.main }}/>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" sx={{ fontWeight: '400' }}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            {content}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </>
    );
}

export default DetailsCardComponent;