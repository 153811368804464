import { AppState } from "@auth0/auth0-react";
import { singleton } from "tsyringe";
import { IAuthUser, IUserInfoResponse } from "../../typings/CommonTypes";
import { AuthorizationType } from "../../typings/enums/AuthorizationTypeEnum";
import { IAuthorizationService } from "./interfaces/IAuthorizationService";

@singleton()
export class AuthorizationService implements IAuthorizationService {
    // no loggerService because of circular dependency
    private readonly localStorageKey: string = 'classic-ui-user';
    private readonly apiUrl: string = process.env.API_URL || '';
    
    
    
    getCurrentUser(): IAuthUser {
        const localStorageUser : string | null = localStorage.getItem(this.localStorageKey);
        if(!localStorageUser) {
            let currentUser = this.initDefaultAuthUser();
            return currentUser;
        }

        let currentUser = JSON.parse(localStorageUser);
        return currentUser;
    }

    getAccessToken(): string {
        const currentUser = this.getCurrentUser();
        return currentUser.accessToken;
    }


    onLoginRedirect = async (appState?: AppState): Promise<void> => {
        const authUser: IAuthUser = this.deserilizeAuth0User();

        // We are setting the localStorage before we make the call to get further details for the user
        // Just so we can use the token we have authorized from Auth0
        localStorage.setItem(this.localStorageKey, JSON.stringify(authUser));

        const userInfo: IUserInfoResponse = await this.getUserDetailsViaHttpAsync(authUser);
        if(userInfo) {
            authUser.firstName = userInfo.firstName;
            authUser.lastName = userInfo.lastName;
            authUser.currencySymbol = userInfo.currencySymbol;
            authUser.roles = userInfo.roles;
            authUser.permissions = userInfo.permissions;
        }
        
        localStorage.setItem(this.localStorageKey, JSON.stringify(authUser));
        //this.history.replace(appState?.returnTo || window.location.pathname);    
    };


    removeUserFromLocalStorage(): void {
        localStorage.removeItem(this.localStorageKey);
    }

    getAuth0ClientId(): string {
        return process.env.REACT_APP_AUTH0_CLIENT_ID || '';
    }

    getAuth0Domain(): string {
        return process.env.REACT_APP_AUTH0_DOMAIN || '';
    }

    getAuth0Audience(): string {
        return process.env.REACT_APP_AUTH0_AUDIENCE || '';
    }


    /** deserilize token and validate for missing properties */
    private deserilizeAuth0User(): IAuthUser {
        let authUser: IAuthUser = this.initDefaultAuthUser();
    
        let auth0ClientId = this.getAuth0ClientId();
        for (const localStorageKey in localStorage){
            if(localStorageKey.includes(auth0ClientId)) {
                const localStorageValue = localStorage.getItem(localStorageKey);
                if(localStorageValue) {
                    const localStorageObject= JSON.parse(localStorageValue);

                    if (localStorageObject.body){
                        authUser.expiresAt = localStorageObject.expiresAt;
                        authUser.authorizationType = AuthorizationType.LivedoohCredentials;
                        authUser.accessToken = localStorageObject.body.access_token
                    }
                    else {
                        authUser.email = localStorageObject.decodedToken.user.email;
                        authUser.userId = localStorageObject.decodedToken.user.sub;

                        const organizationId = localStorageObject.decodedToken.claims['http://local.livedooh.com/organization'];
                        const userGroups = localStorageObject.decodedToken.claims['http://local.livedooh.com/groups'];

                        authUser.organizationId = organizationId;
                        authUser.userGroups = userGroups;
                    }
                }
            }
        }
    
        return authUser;
    }

    private async getUserDetailsViaHttpAsync(authUser: IAuthUser): Promise<IUserInfoResponse> {
        const userInfoResponse = await fetch(`${this.apiUrl}/users/v2/${authUser.userId}/user-info/${authUser.organizationId}`, {
            headers: {
                Authorization: `Bearer ${authUser.accessToken}`
            }
        });

        const userInfo = await userInfoResponse.json();
        return userInfo;
    }

    private initDefaultAuthUser(): IAuthUser {
        let authUser: IAuthUser = {
            accessToken: "",
            expiresAt: 0,
            email: "",
            userId: "",
            authorizationType: AuthorizationType.LivedoohCredentials,
            organizationId: 0,
            firstName: "",
            lastName: "",
            currencySymbol: "",
            userGroups: [],
            permissions: [],
            roles: []
        };

        return authUser;
    }
}