import { Grid, Typography, FormControl, OutlinedInput, TextField, Button, Divider, Backdrop, CircularProgress, Card } from '@mui/material';
import { ImageSquare, LinkSimpleHorizontal } from 'phosphor-react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React, { useRef, useState } from 'react';

import { container } from 'tsyringe';
import { AzureStorageService } from '../../../../../../utils/services/AzureStorageService';
import { IAzureStorageService } from '../../../../../../utils/services/interfaces/IAzureStorageService';
import { MediaEventService } from '../../../../../../utils/services/MediaEventService';
import { IMediaEventService } from '../../../../../../utils/services/interfaces/IMediaEventService';
import { CreativePreview } from './CreativePreview';
import { BuilderDto } from '../../../../../../typings/models/campaignBuilder/BuilderDto';
import { useTranslation } from 'react-i18next';

import '/src/styles/layout/campaign/campaignBuilder/CreativeEditingStyle.scss';

const CreativeEditing = ({ campaignDto, setCampaignDto, builderDto, setBuilderDto }) => {

    const azureStorageService: IAzureStorageService = container.resolve(AzureStorageService);
    const mediaEventService: IMediaEventService = container.resolve(MediaEventService);

    const [preview, setPreview] = useState<boolean>(false);
    const [templateData, setTemplateData] = useState<any>({ imageSrc: null });
    const [uploadedFile, setUploadedFile] = useState<any>({ imageSrc: null });
    const [uploadedFileType, setUploadedFileType] = useState<any>({ imageSrc: null });

    const [t, i18n] = useTranslation('locale');

    let accepts = "image/png, image/gif, image/jpeg, video/mp4";

    const uploadFileInput = useRef();
    let iframeRef = useRef<HTMLIFrameElement>()

    window.onmessage = (e) => {
        console.log('Received ' + e.data.type);
        if (e.data.type === "TEMPLATE_STATE") {
            setTemplateData(e.data.properties)
        }
    };

    const handlePreview = () => {
        setPreview(!preview);
    }

    const handleFileChange = async (e: any) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = () => {
            if (file.type === 'video/mp4') {
                mediaEventService.setVideo(reader.result as string, iframeRef);

                setTemplateData({
                    ...templateData,
                    videoSrc: reader.result
                });
            }
            else {
                mediaEventService.setImage(reader.result as string, iframeRef);

                setTemplateData({
                    ...templateData,
                    imageSrc: reader.result
                });
            }

            setCampaignDto({
                ...campaignDto,
                templateData: templateData
            });
        }

        // Sets the file type for saving in placeholders.json
        if (file.type.includes('video')) {
            setUploadedFileType('videoFile')
        }
        else {
            setUploadedFileType('imageFiIe')
        }

        await azureStorageService.createBlobInContainer(file);

        setUploadedFile(`https://playerteststorage.blob.core.windows.net/mbc/${file.name}`)
    };

    const onIframeLoad = () => {
        mediaEventService.setDesign(iframeRef);
    };

    const handleCampaignName = (event) => {
        setCampaignDto({
            ...campaignDto,
            name: event.target.value
        });

        updateBuilderDto(event.target.value, campaignDto.duration);
    };

    const handleMediaDuration = (event) => {
        setCampaignDto({
            ...campaignDto,
            duration: event.target.value
        });

        updateBuilderDto(campaignDto.name, event.target.value);
    };

    const updateBuilderDto = (name, duration) => {
        let isNextButtonClickable: boolean = false;

        if (name && duration && duration != "0") {
            isNextButtonClickable = true;
        }

        const editedBuilderDto: BuilderDto = {
            isNextButtonClickable: isNextButtonClickable,
            setIsNextButtonClickable: isNextButtonClickable
        }
        setBuilderDto(editedBuilderDto)
    };

    return (
        <div>
            {
                preview && <CreativePreview templatePreviewUrl={campaignDto.templatePreviewUrl} templateData={templateData} runPreviewRequested={preview} handleClose={() => setPreview(false)} />
            }
            <Grid container spacing={2} flexWrap={'nowrap'} className={"creative-editing-container"}>
                <Grid item className={"creative-editing-item"}>
                    <Grid
                        container
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems='center'
                        gap={"16px"}
                    >
                        <Typography className={"creative-editing-details"}>{t('creativeEditing.details')}</Typography>
                        <Grid item className={"creative-editing-form-control-wrapper"}>
                            <FormControl className={"creative-editing-form-control"}>
                                <OutlinedInput
                                    placeholder={'Campaign Name'}
                                    onChange={handleCampaignName}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>

                        <Grid item className={"creative-editing-form-control-wrapper"}>
                            <FormControl className={"creative-editing-form-control"}>
                                <TextField
                                    id="outlined-basic"
                                    placeholder={'Duration'}
                                    variant="outlined"
                                    type={'number'}
                                    fullWidth
                                    onChange={handleMediaDuration}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems='center'
                        gap={"8px"}
                        mt={"24px"}>
                        <Typography className={"creative-editing-details"}>{t('creativeEditing.media')}</Typography>
                        <Grid item className={"creative-editing-item-media"}>
                            <Grid className={"creative-editing-media"}>
                                <Button
                                    variant='outlined'
                                    startIcon={<ImageSquare size={21} />}
                                    component="label"
                                    className="creative-editing-media-button"
                                >
                                   {t('campaignBuilder.editor.uploadFile')}
                                    <input
                                        ref={uploadFileInput}
                                        type="file"
                                        accept={accepts}
                                        id="upload-image" hidden
                                        onChange={handleFileChange}
                                    />
                                </Button>
                                <Button
                                    variant='outlined'
                                    color='inherit'
                                    startIcon={<LinkSimpleHorizontal size={21} />}
                                    className="creative-editing-media-button"
                                >
                                    {t('campaignBuilder.editor.uploadImageUrl')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <Backdrop className={"creative-editing-backdrop"}
                        open={false}
                    >
                        <CircularProgress color="primary" />
                    </Backdrop>
                    <div className={"builder-wrapper"}>
                        <Card className={"builder-wrapper-card"} variant="outlined">
                            <Grid
                                container
                                justifyContent={'center'}
                                alignItems={'center'}
                                sx={{ padding: 2 }}
                            >
                                <iframe
                                    ref={iframeRef}
                                    id="singleframeid"
                                    loading='lazy'
                                    onLoad={onIframeLoad}
                                    frameBorder=" 0"
                                    className={"creative-editing-iframe"}
                                    src={campaignDto.templatePreviewUrl}
                                >
                                </iframe>
                            </Grid>
                            <Divider variant="fullWidth" />
                            <>
                                <div className="iframe-controls-container">
                                    <Grid container flex={1} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                                        <Grid item container flexDirection={'column'} ml={'auto'} width="auto">
                                            <Button
                                                className="preview-button"
                                                color='inherit'
                                                variant='outlined'
                                                startIcon={<VisibilityOutlinedIcon />}
                                                onClick={handlePreview}>
                                                    {t("campaignBuilder.editor.preview")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </>
                        </Card>
                    </div>
                </Grid>
            </Grid>
        </div >
    );
};

export default CreativeEditing;