import React, { useState } from "react";
import { Card, CardContent, Chip, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";

import { BuilderDto } from "../../../../../../typings/models/campaignBuilder/BuilderDto";
import '/src/styles/layout/campaign/campaignBuilder/TemplateSelector.scss';
import { DataGridPro, GridColDef, GridRowsProp } from "@mui/x-data-grid-pro";
import {useTranslation} from "react-i18next";

import '/src/styles/layout/campaign/campaignBuilder/DisplaySelector.scss';

const DisplayGridList = ({ displays, enableSelection, isLoading, campaignDto, setCampaignDto, builderDto, setBuilderDto }) => {

    const [t, i18n] = useTranslation('locale');
    const getAttributeValue = (attributes: any, key: string): string => {
        if (!attributes || !attributes.length) {
            return "";
        }

        for (var i in attributes) {
            if (attributes[i].key.toLowerCase() == key.toLowerCase()) {
                return attributes[i].value;
            }
        }

        return "";
    }

    const onSelectInventory = (ids) => {
        const selectedIDs = new Set(ids);
        const selectedRows = displays.filter((row) =>
            selectedIDs.has(row.id),
        );

        setCampaignDto({
            ...campaignDto,
            inventories: selectedRows
        });

        let isNextStepAvailable = selectedRows != null && selectedRows.length > 0;

        const editedBuilderDto: BuilderDto = {
            isNextButtonClickable: isNextStepAvailable,
            setIsNextButtonClickable: isNextStepAvailable
        }
        setBuilderDto(editedBuilderDto);
    }

    const columns: GridColDef[] = [
        { field: 'col1', headerName: t('displaySelection.storeNumber') as string, flex: 0.5 },
        { field: 'col2', headerName: t('displaySelection.storeName') as string, flex: 1.5 },
        { field: 'col3', headerName: t('displaySelection.address') as string, flex: 1.5 },
        { field: 'col4', headerName: t('displaySelection.city') as string, flex: 1 },
        { field: 'col5', headerName: t('displaySelection.display') as string, flex: 2 }
    ];

    const rows: GridRowsProp = displays.map(row => {
        return {
            id: row.id,
            col1: getAttributeValue(row.properties, "storenumber"),
            col2: getAttributeValue(row.attributes, "storename"),
            col3: getAttributeValue(row.attributes, "address"),
            col4: getAttributeValue(row.attributes, "city"),
            col5: row.properties["name"]
        }
    });

    return (
        <>
            <div className={"display-grid-list-container"}>
                {displays.length > 0 || columns.length > 0 ?
                    <DataGridPro
                        sx={{ border: 0 }}
                        loading={isLoading}
                        rows={rows}
                        columns={columns}
                        getRowId={(row) => row?.id}
                        pageSizeOptions={[10, 25, 50, 100]}
                        pagination
                        paginationMode='server'
                        checkboxSelection={enableSelection}
                        onRowSelectionModelChange={onSelectInventory}
                    /> :
                    !isLoading && <div style={{ width: '100%' }}>
                        <Typography>There is no data!</Typography>
                    </div>
                }
            </div>
        </>
    );
};

export default DisplayGridList;