import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const Wrapper = styled('div')(({ theme }) => ({
	width: '100vw',
	height: '100vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: '#fff'
}));

const LoadingIndicator = () => (
	<Wrapper data-testid='loading-indicator'>
		<CircularProgress/>
	</Wrapper>
);

export default LoadingIndicator;
