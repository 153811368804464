// Should come from context api
import { IUserAccountMenu } from "../../../../typings/CommonTypes"
import { Divider } from "@mui/material"
import { CaretDown, CaretUp, SignOut } from 'phosphor-react'
// MUI Components imports
import { 
        Menu, 
        List, 
        ListItem,
        ListItemButton, 
        ListItemIcon,
        ListItemText
    } from "@mui/material"

import Fade from '@mui/material/Fade';

// Locale react-i18next
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react';
import { container } from "tsyringe";
import { IAuthorizationService } from "../../../../utils/services/interfaces/IAuthorizationService";
import { AuthorizationService } from "../../../../utils/services/AuthorizationService";
import { LoggerService } from "../../../../utils/services/LoggerService";
import { LogVerbosityLevel } from "../../../../typings/enums/LogVerbosityLevelEnum";
import { ILoggerService } from "../../../../utils/services/interfaces/ILoggerService";

// todo: use classes instead of export const and arrow functions
export const UserAccountMenu = ({name, email, userMenu, setUserMenu}: IUserAccountMenu) => {
    const authorizationService: IAuthorizationService = container.resolve(AuthorizationService);
    const loggerService: ILoggerService = container.resolve(LoggerService);
    
    const currentUser = authorizationService.getCurrentUser();

    const menuData = [
        {
            id: 1,
            title: `${currentUser.firstName} ${currentUser.lastName}`,
            handleEvent: 'handleClose',
            classes: 'classes.menuItem',
            disabled: true,
            property: 'fullName'
        },
        {
            id: 2,
            subtitle: currentUser.email,
            handleEvent: 'handleClose',
            classes: 'classes.menuItem',
            disabled: true,
            property: 'email'
        },
        {
            id: 3,
            divider: <Divider variant="fullWidth"/>
        },
        {
            id: 4,
            subtitle2: 'userDropDownMenu.settings'
        },
        {
            id: 5,
            text: "userDropDownMenu.selectedLang",
            handleEvent: 'handleClose',
            classes: 'classes.menuItem',
            disabled: false,
            sx: {mb:1.5},
            expandMoreIcon: <CaretUp size={24}/>,
            expandLessIcon: <CaretDown size={24}/>,
            langButton: true,
        },
        {
            id: 7,
            divider: <Divider variant="fullWidth"/>
        },
        {
            id: 8,
            text: "userDropDownMenu.signOut",
            handleEvent: 'handleClose',
            classes: 'classes.menuItem',
            disabled: false,
            icon: <SignOut className="user-menu-icon"/>,
            logoutButton: true
        },
    ];

    const [t, i18n] = useTranslation('locale')
    const { logout } = useAuth0();

    const handleLogout = () => { 
        loggerService.logMessage(`User about to log off`, LogVerbosityLevel.Information);
        authorizationService.removeUserFromLocalStorage();
        logout({ logoutParams: { returnTo: window.location.origin } })    }

    const open = Boolean(userMenu);
    const handleClose = () => {
        setUserMenu(null);
    };

    return(
        <Menu
            id="basic-menu"
            anchorEl={userMenu}
            open={open}
            onClose={handleClose}
            elevation={1}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
            TransitionComponent={Fade}
        >
            <List className="nested-list-subheader"
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItem>
                    <ListItemText
                        primary={name == null ? "" : name}
                        secondary={currentUser.email}/>
                </ListItem>
                <Divider></Divider>
                <ListItemButton onClick={handleLogout}>
                    <ListItemIcon className="list-item-icon">
                        <SignOut className="user-menu-icon" />
                    </ListItemIcon>
                    <ListItemText primary={"Sign Out"} />
                </ListItemButton>
            </List>
        </Menu>
    )
}