// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimaryMain": `#0076fb`,
	"colorPrimaryLight": `#c7e1ff`,
	"colorPrimaryDark": `#004694`,
	"colorPrimaryContrastText": `#ffffff`,
	"colorPrimaryContainedHoverBackground": `#005ec7`,
	"colorPrimaryOutlinedHoverBackground": `rgba(0, 118, 251, 0.08)`,
	"colorPrimaryOutlinedRestingBorder": `rgba(0, 118, 251, 0.5)`,
	"colorSecondaryMain": `green`,
	"colorSecondaryLight": `#cf8aff`,
	"colorSecondaryDark": `#7000bd`,
	"colorSecondaryContrastText": `#ffffff`,
	"colorTertiaryMain": `#4adede`,
	"colorTertiaryLight": `#cbf6f6`,
	"colorTertiaryDark": `#1ea4a4`,
	"colorTertiaryContrastText": `#ffffff`,
	"colorErrorMain": `#f27968`,
	"colorErrorLight": `#facdc7`,
	"colorErrorDark": `#e12d13`,
	"colorErrorContrastText": `#ffffff`,
	"colorTextPrimary": `#161718`,
	"colorTextSecondary": `#5e6368`,
	"colorTextDisabled": `#acb0b4`,
	"colorTextHighlight": `#0076fb`,
	"colorYellow400": `#fbb832`,
	"colorGreen600": `#06bc76`,
	"colorSkyBlue400": `#1ca7ec`,
	"colorBrandPurple400": `#a522ff`,
	"colorRed300": `#f27968`,
	"colorIllustrationOrange": `#f69c5b`,
	"colorNeutral300": `#acb0b4`,
	"colorSecondaryStatesOutlinedHoverBackground": `rgba(165, 34, 255, 0.1)`,
	"backdropOverlay": `rgba(22, 23, 24, 0.5)`
};
export default ___CSS_LOADER_EXPORT___;
