import {Chip, useTheme} from "@mui/material";
import {StageType} from "../enum/StageType";

interface ChipStyleProperties {
    [stage: string]: {
        backgroundColor: string;
        textColor: string;
    };
}

export const getCampaignStageChipProperties = (stage: string): { backgroundColor: string; textColor: string } => {

    const theme = useTheme();

    const campaignStageChipProperties: ChipStyleProperties = {
        [StageType.Live]: {
            backgroundColor: theme.palette.other.colorGreen600,
            textColor: theme.palette.primary.contrastText
        },
        [StageType.Upcoming]: {
            backgroundColor: theme.palette.info.main,
            textColor: theme.palette.primary.contrastText
        },
        [StageType.Expired]: {
            backgroundColor: theme.palette.error.main,
            textColor: theme.palette.primary.contrastText
        },
        [StageType.Cancelled]: {
            backgroundColor: theme.palette.background.colorNeutral100,
            textColor: theme.palette.text.primary
        },
        [StageType.Processing]: {
            backgroundColor: theme.palette.background.colorNeutral100,
            textColor: theme.palette.text.primary
        }
    };

    return campaignStageChipProperties[stage];
}

export default getCampaignStageChipProperties;