import React, { useEffect, useState } from 'react';
import DisplayGridList from './DisplayGridList';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { container } from 'tsyringe';
import { ILdskHttpError } from '../../../../../../typings/apiModels/CommonApiModel';
import { LogVerbosityLevel } from '../../../../../../typings/enums/LogVerbosityLevelEnum';
import { LoggerService } from '../../../../../../utils/services/LoggerService';
import { ILoggerService } from '../../../../../../utils/services/interfaces/ILoggerService';
import { IDisplaysListResponse } from '../../../../../../typings/apiModels/campaigns/DisplayModel';
import { IInventoryHttpService } from '../../../../../../utils/services/interfaces/IInventoryHttpService';
import InventoryHttpService from '../../../../../../utils/services/InventoryHttpService';

const DisplaySelection = ({ campaignDto, setCampaignDto, builderDto, setBuilderDto }) => {

    const displayHttpService: IInventoryHttpService = container.resolve(InventoryHttpService);
    const loggerService: ILoggerService = container.resolve(LoggerService);

    const [t, i18n] = useTranslation('locale');
    const [isLoading, setIsLoading] = useState(true);

    const [displays, setDisplays] = useState<DataGridProProps["rows"]>([]);

    useEffect(() => {
        (async () => {
            await getDisplays();
        }
        )();
    }, []);

    const getDisplays = async () => {
        await displayHttpService.getAllInventoriesAsync()
            .then(async (response: AxiosResponse<IDisplaysListResponse>) => {
                console.log(response.data.items)
                setDisplays(response?.data.items || []);
            })
            .catch((errorResponse: ILdskHttpError) => {
                console.log(errorResponse);
                loggerService.logMessage(`Error fetching displays ${errorResponse.message}`, LogVerbosityLevel.Error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <DisplayGridList
            enableSelection={true} 
            displays={displays}
            isLoading={isLoading}
            campaignDto={campaignDto}
            setCampaignDto={setCampaignDto}
            builderDto={builderDto}
            setBuilderDto={setBuilderDto} />
    );
};

export default DisplaySelection;