import { Box } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../common/PageTitle';
import { setTabTitle } from '../../common/TabTitle';

export const InventoriesView = ()=> {
    const [t, i18n] = useTranslation('locale');
    setTabTitle(t('inventories.tabTitle'));

    return(
        <Box>
            <PageTitle pageTitle={t('inventories.title')}></PageTitle>
        </Box>
    )
}