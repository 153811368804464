import { singleton } from "tsyringe";
import { IAzureStorageService } from "./interfaces/IAzureStorageService";
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";

@singleton()
export class AzureStorageService implements IAzureStorageService {

    private readonly accountName: string;
    private readonly accountConnectionString: string;


    constructor() {
        this.accountName = process.env.AZURE_ACCOUNT_NAME || '';
        this.accountConnectionString = process.env.AZURE_CONNECTION_STRING || '';
    }

    /**
     * Uploads file to an azure storage
     * @param file The file to upload
     */
    async createBlobInContainer(file: File): Promise<void> {
        // Calls directly from this function since it need the actual file from event target
        let blobService = new BlobServiceClient(
            `https://${this.accountName}.blob.core.windows.net/${this.accountConnectionString}`
        )

        const containerClient: ContainerClient = blobService.getContainerClient('store-dms');

        // create blobClient for container
        const blobClient = containerClient.getBlockBlobClient(file.name);

        // set mimetype as determined from browser with file upload control
        const options = {
            blobHTTPHeaders: {
                blobContentType: file.type
            }
        };

        // upload file - this wont work on localhost
        await blobClient.uploadData(file, options);
    }
}
