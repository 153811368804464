import { createTheme } from '@mui/material/styles';
import '../styles/theme/_variables.scss';
import styles from '../styles/theme/style.module.scss';
import {Color, CommonColors, TypeText, TypeBackground, TypeOther, Components} from '@mui/material/styles/createPalette';

const theme = createTheme({
  palette: {
    primary: {
      main: styles.colorPrimaryMain,
      light: styles.colorPrimaryLight,
      dark: styles.colorPrimaryDark,
      contrastText: styles.colorPrimaryContrastText
    },
    secondary: {
      main: styles.colorSecondaryMain,
      light: styles.colorSecondaryLight,
      dark: styles.colorSecondaryDark,
      contrastText: styles.colorSecondaryContrastText,
    },
    error: {
      main: styles.colorErrorMain,
      light: styles.colorErrorLight,
      dark: styles.colorErrorDark,
      contrastText: styles.colorErrorContrastText
    },
    text: {
      primary: styles.colorTextPrimary,
      secondary: styles.colorTextSecondary,
      disabled: styles.colorTextDisabled,
    },
    other: {
      colorBrandPurple400: styles.colorBrandPurple400,
      colorGreen600: styles.colorGreen600,
      backdropOverlay: styles.backdropOverlay
    },
    background: {
      colorNeutral100: styles.colorNeutral100
    }
  },
  typography: {
    fontFamily: [
      'Roboto'
    ].join(','),
    fontWeightLight: 400
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: 240
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: styles.colorPrimaryMain,
          color: styles.colorCommonWhite,
          "&:hover": {
            backgroundColor: styles.colorPrimaryContainedHoverBackground
          }
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px"
        },
      },
    }
  }
});
export default theme;