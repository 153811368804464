import React, { useState } from 'react';
import {Grid, Stepper, Step, StepLabel, StepConnector, StepIcon, Button} from '@mui/material';
import TemplateSelection from "./campaignBuilderSteps/TemplateSelection";
import CreativeEditing from "./campaignBuilderSteps/creativeEditor/CreativeEditing";
import DisplaySelection from "./campaignBuilderSteps/displaySelection/DisplaySelection";
import ScheduleCreation from "./campaignBuilderSteps/ScheduleCreation";
import Summary from "./campaignBuilderSteps/Summary";
import {CampaignBuilderSteps} from "../enum/CampaignBuilderSteps";
import {useTranslation} from "react-i18next";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {convertToCamelCase} from "../../../common/TextConverter";
import theme from "../../../../theme/theme";
import {CustomDialog} from "../../../common/CustomDialog";
import {CustomDialogParams, defaultCustomDialogParams} from "../../../../typings/CommonTypes";
import {DefaultBuilderDto} from "../../../../typings/models/campaignBuilder/BuilderDto";
import {DefaultCampaignDto} from "../../../../typings/apiModels/campaigns/CampaignModel";

const CampaignBuilderProgressTracker = () => {
    const [t, i18n] = useTranslation('locale');
    const [activeStep, setActiveStep] = useState(0);
    const [campaignDto, setCampaignDto] = useState(DefaultCampaignDto);
    const steps = Object.values(CampaignBuilderSteps);
    const [builderDto, setBuilderDto] = useState(DefaultBuilderDto);

    const [customDialogParams, setCustomDialogParams] = useState(defaultCustomDialogParams);
    const [customDialogOpen, setCustomDialogOpen] = useState(false);

    const handleStepClick = (index) => {
        if ((index < activeStep || calculateStepColors(index).completed) && index === 0) {
            setCustomDialogParams(dialogParams);
            setCustomDialogOpen(true);
        } else {
            setActiveStep(index);
        }
    };

    const dialogParams : CustomDialogParams = {
        onConfirm(): void {
        },
        title: t("templates.buttons.cancelTitle") as string,
        description: t("templates.buttons.cancelDescription") as string,
        buttonCancelText: t("templates.buttons.cancelButton") as string,
        buttonConfirmText: t("templates.buttons.confirmButton") as string,
        shouldRedirect: true,
        redirectUrl:  "/campaigns"
    };

    const handleCancel = () => {
        setCustomDialogParams(dialogParams);
        setCustomDialogOpen(true);

        dialogParams.onConfirm = () => {
            setCampaignDto(DefaultCampaignDto);
            setBuilderDto(DefaultBuilderDto);
            window.location.assign(`/#/create`);
            setActiveStep(0);

            setCustomDialogOpen(false);
        };
    };

    const handleNext = () => {
        if (activeStep < steps.length - 1) {
            setActiveStep(activeStep + 1);
        } else {
            // Handle completion, e.g., navigate to the next page
            console.log('Campaign creation completed!');
        }
    };

    function calculateStepColors(index: number) {
        const completed = activeStep > index;
        const selected = activeStep === index;
        const stepTitleColor = selected ? theme.palette.primary.main : theme.palette.text.primary;
        const stepSubTitleColor = selected ? theme.palette.primary.main : theme.palette.text.secondary;
        const stepIconBgColor = completed ? theme.palette.other.colorGreen600 : (selected ? theme.palette.primary.main : theme.palette.text.disabled);
        return {completed, selected, stepTitleColor, stepSubTitleColor, stepIconBgColor};
    }

    return (
        <Grid container spacing={3} style={{padding: '0px', gap: '8px'}}>
            <Grid item xs={12} style={{ minWidth: '100%' }}>
                <Stepper activeStep={activeStep} connector={<StepConnector />}>
                    {steps.map((label, index) => {
                        const shouldShowSubtitle = label !== CampaignBuilderSteps.summary;
                        const {
                            completed,
                            selected,
                            stepTitleColor,
                            stepSubTitleColor,
                            stepIconBgColor
                        } = calculateStepColors(index);

                        return (
                            <Step key={label}>
                                <StepLabel
                                    icon={
                                        <CheckCircleIcon sx={{ color: stepIconBgColor, width: '24px', height: '24px',
                                        borderRadius: '64px' }}/>
                                    }
                                    onClick={() => (completed || selected) ? handleStepClick(index) : null}
                                    StepIconComponent={(props) => (
                                        <StepIcon
                                            {...props}
                                            completed={completed}
                                            error={selected}
                                            disabled={!completed && !selected}
                                        />
                                    )}
                                >
                                    <div>
                                        <div className="title" style={{ color: stepTitleColor}}>
                                            {t('campaignBuilder.progressTracker.' + convertToCamelCase(label))}</div>
                                        {shouldShowSubtitle &&
                                            <div className="subtitle" style={{ color: stepSubTitleColor}}>
                                                {t('campaignBuilder.progressTracker.' + convertToCamelCase(label) + 'Subtitle')}</div>}
                                    </div>
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            <Grid item xs={12} style={{paddingTop: '0px'}}>
                {activeStep === 0 &&
                    <TemplateSelection setCampaignDto={setCampaignDto}
                                       setBuilderDto={setBuilderDto}
                    />}
                {activeStep === 1 &&
                    <CreativeEditing campaignDto={campaignDto}
                                     setCampaignDto={setCampaignDto}
                                     builderDto={builderDto}
                                     setBuilderDto={setBuilderDto}
                    />}
                {activeStep === 2 &&
                    <DisplaySelection campaignDto={campaignDto}
                                      setCampaignDto={setCampaignDto}
                                      builderDto={builderDto}
                                      setBuilderDto={setBuilderDto}
                    />}
                {activeStep === 3 &&
                    <ScheduleCreation campaignDto={campaignDto}
                                      setCampaignDto={setCampaignDto}
                                      setBuilderDto={setBuilderDto}
                    />}
                {activeStep === 4 &&
                    <Summary campaignDto={campaignDto}
                             setCampaignDto={setCampaignDto}
                             builderDto={builderDto}
                             setBuilderDto={setBuilderDto}
                    />
                }
            </Grid>
            <Grid item xs={12} style={{paddingTop: "8px"}}>
                <Grid container justifyContent="flex-end">
                    <Button onClick={() => handleCancel()} style={{ textTransform: 'none', backgroundColor: 'transparent',
                        color: theme.palette.primary.main, marginRight: "8px" }}>
                    {t('campaignBuilder.buttons.cancel')}
                    </Button>
                    <Button onClick={() => handleNext()}
                            disabled={!builderDto.isNextButtonClickable}
                            style={{ textTransform: 'none' }} autoFocus variant={"contained"}>
                        {activeStep === steps.length - 1 ? t("campaignBuilder.buttons.createAndPublish") : t("campaignBuilder.buttons.next")}
                    </Button>
                </Grid>
            </Grid>
            <CustomDialog customDialogParams={customDialogParams} setCustomDialogParams={setCustomDialogParams}
                          customDialogOpen={customDialogOpen}  setCustomDialogOpen={setCustomDialogOpen}/>
        </Grid>
    );
};

export default CampaignBuilderProgressTracker;
