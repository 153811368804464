import axios, { AxiosError } from "axios";
import { container } from "tsyringe";
import { LoggerService } from "./services/LoggerService";
import { LogVerbosityLevel } from "../typings/enums/LogVerbosityLevelEnum";
import { AuthorizationService } from "./services/AuthorizationService";
import { ILoggerService } from "./services/interfaces/ILoggerService";
import { IAuthorizationService } from "./services/interfaces/IAuthorizationService";

const loggerService: ILoggerService = container.resolve(LoggerService);
const authorizationService: IAuthorizationService = container.resolve(AuthorizationService);

export const httpClient = axios.create({
  baseURL: process.env.API_URL
});

httpClient.interceptors.request.use(async function (config) {
    const token = authorizationService.getAccessToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.timeout = 20000;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

httpClient.interceptors.response.use(function (response) {
  return response;
},
  function (error: AxiosError) {
    const statusCode: number | undefined = error?.response?.status;
    if (!statusCode) {
      return;
    }


    const ldskApiError: { message: string; errorStack: string; url: string; statusCode: number } = {
      message: error?.message,
      statusCode: statusCode,
      url: `${error?.config?.baseURL}${error?.config?.url}`,
      errorStack: error?.stack
    }

    switch (statusCode) {
      // Unauthorized,
      case 401:
        loggerService.logMessage(`User will be logged out due to invalid token (401)`, LogVerbosityLevel.Information);
        window.location.assign("/#/expired-token");
        break;

      // Forbidden
      case 403:
        // display more stylish alert 
        alert('Insufficient access');
        throw ldskApiError;
       
      // Internal server error
      case 500:
        loggerService.logMessage(`Internal server error. Message: ${ldskApiError.message}. StackTrace: ${ldskApiError.errorStack}`, LogVerbosityLevel.Error);
        throw ldskApiError;
       
      default:
        throw ldskApiError;
    }
  });