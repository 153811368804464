import CampaignBuilderProgressTracker from "./CampaignBuilderProgressTracker";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import React from "react";
import {PageHeaderComponent} from "../../../common/PageHeaderComponent";

export const CampaignBuilder = ()=> {
    const [t, i18n] = useTranslation('locale');

    return (
        <Box>
            <PageHeaderComponent
                headerTitle={t('campaigns.title')}
            />
            <CampaignBuilderProgressTracker/>
        </Box>
    );
}