import "reflect-metadata";
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { App } from './App'
import { I18nextProvider } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next'
import locale_en from './locales/en/locale.json'
import { HashRouter as Router } from 'react-router-dom';
import { IAuthorizationService } from './utils/services/interfaces/IAuthorizationService';
import { container } from 'tsyringe';
import { AuthorizationService } from './utils/services/AuthorizationService';
import { ILoggerService } from "./utils/services/interfaces/ILoggerService";
import { LoggerService } from "./utils/services/LoggerService";
import { createBrowserHistory } from "history";
import { LogVerbosityLevel } from "./typings/enums/LogVerbosityLevelEnum";
import { createRoot } from 'react-dom/client';
import {LicenseInfo} from "@mui/x-data-grid-pro";
import {LicenseKey} from "./utils/constants/AppConstants";

LicenseInfo.setLicenseKey(LicenseKey);

i18next
.use(LanguageDetector)
.init({
    supportedLngs: ['en'],
    nonExplicitSupportedLngs: true,
    load: 'languageOnly',
    lng: 'en',
    fallbackLng: 'nl',
    resources: {
        en: {
            locale: locale_en
        }
    },
    detection: {
        order: ['localStorage', 'navigator', 'htmlTag']
    },
})

const authorizationService: IAuthorizationService = container.resolve(AuthorizationService);
const loggerService: ILoggerService = container.resolve(LoggerService);
const history = createBrowserHistory();
const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(

    <I18nextProvider i18n={i18next}>
        <Router>
            <Auth0Provider
                domain={authorizationService.getAuth0Domain()}
                clientId={authorizationService.getAuth0ClientId()}
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: authorizationService.getAuth0Audience()
                }}
                cacheLocation="localstorage"
                onRedirectCallback={async function (appState?: AppState) {
                    await authorizationService.onLoginRedirect(appState);
                    setTimeout(function () {
                        loggerService.logMessage(`User successfully authorized`, LogVerbosityLevel.Information);
                    }, 2000);

                    history.replace(appState?.returnTo || window.location.pathname);
                }} >
                <App />
            </Auth0Provider>
        </Router>
    </I18nextProvider>
)