import './styles/index.scss'
import 'material-symbols/rounded.scss'
import { Layout } from './modules/Layout';
import { appContext } from './contexts/AppContext';
import { useState } from 'react';
export const App = ()=> {
    // todo: research how to implement appContext
    const [sidebarOpen, setSidebarOpen] = useState<boolean>()
    return(
        <appContext.Provider value={
            {
                sidebarOpen,
                setSidebarOpen,
            }
        }>
            <Layout />
            
        </appContext.Provider>
    )
}