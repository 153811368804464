interface PageTitleProps {
    pageTitle: string
}

import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';

export const PageTitle = ({pageTitle} : PageTitleProps)=> {
    return(
        <Box className="page-title">
            <Box className="page-title-header">
                <Box>
                    <Typography variant="h5" className="page-title-name">{pageTitle}</Typography>
                </Box>
            </Box>
            <Box className="page-title-actions">
                {/* <Button>asdsa</Button> */}
            </Box>
            <Divider className="page-title-divider" />
        </Box>
    )
}