import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'react-i18next';

import {CampaignActions} from "../views/campaigns/enum/CampaignActions";
import {
    MoreVert,
    EditOutlined,
    VisibilityOutlined,
    InfoOutlined,
    CancelOutlined
} from "@mui/icons-material";
import {
    checkIfCampaignCancelIsEnabled,
    checkIfCampaignEditIsEnabled
} from "../views/campaigns/components/CampaignEditableRules";
import theme from "../../theme/theme";

export const ActionsMenu = ({campaign, setCustomDialogParams, setCustomDialogOpen}) => {
    const [t] = useTranslation('locale');

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    let isEditEnabled = checkIfCampaignEditIsEnabled(campaign);
    let isCancelEnabled = checkIfCampaignCancelIsEnabled(campaign);

    const handleModalDialogText = () => {
        const campaignName = campaign.name;
        const customDialog = {
            title: t('customDialog.cancelCampaignTitle'),
            description: t('customDialog.cancelCampaignDescription', { campaignName })
        };
        setCustomDialogParams(customDialogParams => ({ ...customDialogParams, ...customDialog }));
        setCustomDialogOpen(true);
        handleMenuClose();
    }

    const handleActionClick = (action) => {
        handleMenuClose();

        switch (action) {
            case CampaignActions.edit:
                 window.location.assign(`/#/details/${campaign.id}`);
                 break;
            case CampaignActions.preview:
                 window.location.assign(`/#/details/${campaign.id}`);
                 break;
            case CampaignActions.details:
                 window.location.assign(`/#/details/${campaign.id}`);
                 break;
            case CampaignActions.cancel:
                 handleModalDialogText();
                 break;
        }
    };

    const generateMenuItem = (onClick, icon, label, className) => {
        return (
            <MenuItem onClick={onClick} className={className} style={{color: theme.palette.text.primary, borderRadius: '4px', margin: '8px'}}>
                {icon && React.cloneElement(icon, { fontSize: 'small', className: 'menu-item-icon' })}
                <span>{label}</span>
            </MenuItem>
        );
    };

    return (
        <>
            <IconButton onClick={handleMenuOpen}>
                <MoreVert fontSize='small' />
            </IconButton>
            <Menu
                elevation={1}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                anchorEl={anchorEl}
                keepMounted={true}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                TransitionComponent={Fade}
                className={"actions-menu"}
            >
                {isEditEnabled &&
                    generateMenuItem(() => handleActionClick(CampaignActions.edit), <EditOutlined />, t('actionsMenu.edit'), 'menu-item')}
                {generateMenuItem(() => handleActionClick(CampaignActions.preview), <VisibilityOutlined />, t('actionsMenu.preview'), 'menu-item')}
                {generateMenuItem(() => handleActionClick(CampaignActions.details), <InfoOutlined />, t('actionsMenu.details'), 'menu-item')}
                {isCancelEnabled &&
                    generateMenuItem(() => handleActionClick(CampaignActions.cancel), <CancelOutlined />, t('actionsMenu.cancel'), 'menu-item menu-item-cancel')}
            </Menu>
        </>
    );
};
