import {CreativeOrientationType} from "../views/campaigns/enum/CreativeOrientationType";
import PortraitThumbnail from "../../assets/images/campaigns/portrait_16_9.png";
import LandscapeThumbnail_16_9 from "../../assets/images/campaigns/landscape_16_9.png";

export function buildThumbnail(thumbnail, orientation) {
    const thumbnailImage = thumbnail ||
        (orientation === CreativeOrientationType.PORTRAIT ? PortraitThumbnail : LandscapeThumbnail_16_9);

    const imageDimensions =
        orientation === CreativeOrientationType.PORTRAIT
            ? {width: 'auto', height: '56px', marginRight: 10}
            : {width: '56px', height: 'auto', marginRight: 10};

    return (
        <div className="listImageContainer">
            <img src={thumbnailImage} style={imageDimensions} alt="Thumbnail"/>
        </div>
    );
}