import React, {useEffect, useState} from 'react';
import {ITemplatesListResponse} from "../../../../../typings/apiModels/campaigns/TemplateModel";
import {AxiosResponse} from "axios";
import {ILdskHttpError} from "../../../../../typings/apiModels/CommonApiModel";
import {LogVerbosityLevel} from "../../../../../typings/enums/LogVerbosityLevelEnum";
import {ICampaignHttpService} from "../../../../../utils/services/interfaces/ICampaignHttpService";
import {container} from "tsyringe";
import {CampaignHttpService} from "../../../../../utils/services/CampaignHttpService";
import {useTranslation} from "react-i18next";
import {ILoggerService} from "../../../../../utils/services/interfaces/ILoggerService";
import {LoggerService} from "../../../../../utils/services/LoggerService";
import {DataGridProProps} from "@mui/x-data-grid-pro";
import TemplatesGridView from "./templateSelection/GridView";

const TemplateSelection = ({setCampaignDto, setBuilderDto}) => {

    const campaignHttpService: ICampaignHttpService = container.resolve(CampaignHttpService);
    const loggerService: ILoggerService = container.resolve(LoggerService);

    const [t, i18n] = useTranslation('locale');
    const [isLoading, setIsLoading] = useState(true);

    const [templates, setTemplates] = useState<DataGridProProps["rows"]>([]);

    useEffect(() => {
        (async () => {
                await getTemplates();
            }
        )();
    }, []);

    const getTemplates = async () => {
        await campaignHttpService.getTemplatesListAsync()
            .then(async (response:AxiosResponse<ITemplatesListResponse>) => {
                setTemplates(response?.data.mediaFiles || []);
            })
            .catch((errorResponse: ILdskHttpError) => {
                console.log(errorResponse);
                loggerService.logMessage(`Error fetching templates ${errorResponse.message}`, LogVerbosityLevel.Error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <TemplatesGridView templates={templates}
                           setCampaignDto={setCampaignDto}
                           setBuilderDto={setBuilderDto}/>
    );
};

export default TemplateSelection;