import React from "react";

export const formatDateTimeForCampaignView = (dateTimeString) => {
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    const formattedDate = new Date(dateTimeString).toLocaleDateString('en-US', options);
    const [weekday, month, day, year] = formattedDate.split(' ');

    return `${day.replace(',', '')}th ${month} ${year}, ${weekday.replace(/,/g, '')}`;
};

export const extractHoursAndMinutesForCampaignView = (timeString) => {

    const [hours, minutes] = timeString.split('T')[1].split('.')[0].split(':').slice(0, 2);

    return `${hours}:${minutes}`;
};