import React, {useState} from "react";
import {LocalizationProvider, StaticDateRangePicker} from "@mui/x-date-pickers-pro";
 import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {useTranslation} from "react-i18next";

// Import of English and Dutch locales from date-fns
import en from 'date-fns/locale/en-US'
import nl from 'date-fns/locale/nl'

const DatePickerComponent = ({startDate, endDate, onSelectStartDate, onSelectEndDate }) => {
    const [t, i18n] = useTranslation('locale');
    const [selectedDates, setSelectedDates] = useState([
        startDate || null,
        endDate || null,
    ]);

    let locale;
    if (i18n.language === 'en-US' || i18n.language === 'en') locale = en;
    if (i18n.language === 'nl') locale = nl;

    // Starts the calendar on Monday
    if (locale && locale.options) {
        locale.options.weekStartsOn = 1;
    }

    const handleDateChange = (newDates) => {
        setSelectedDates(newDates);
        onSelectStartDate(newDates[0]);
        onSelectEndDate(newDates[1]);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
            <StaticDateRangePicker
                disablePast
                displayStaticWrapperAs="desktop"
                onChange={(newValue) => { handleDateChange(newValue); }}
                value={selectedDates}
                views={["day"]}/>
        </LocalizationProvider>
    );
};

export default DatePickerComponent;
