import { singleton } from "tsyringe";
import { IMediaEventService } from "./interfaces/IMediaEventService";

@singleton()
export class MediaEventService implements IMediaEventService {

    /**
     * Sends SET_IMAGE event to a target iframe
     * Sets image on a content template
     * @param image The image to set 
     * @param iframeRef Target iframe
     */
    public setImage(image: any, iframeRef: any): void {
        let data = {
            type: "SET_IMAGE",
            eventType: "SET_IMAGE",
            image
        };

        this.sendPostMessage(data, iframeRef);
    }

    /**
     * Sends SET_VIDEO event to a target iframe
     * Sets video on a content template
     * @param video The video to set 
     * @param iframeRef Target iframe
     */
    public setVideo(video: any, iframeRef: any): void {
        this.sendPostMessage({
            type: "SET_VIDEO",
            eventType: "SET_VIDEO",
            video
        },
            iframeRef
        );
    }

    /**
     * Sends SET_DESIGN event to a target iframe
     * Sets content template in editing mode
     * @param iframeRef Target iframe
     */
    public setDesign(iframeRef: any): void {
        let data = {
            type: "SET_DESIGN",
            eventType: "SET_DESIGN",
            duid: "D7E9CM001314",
            fontSize: 3
            // Needed to scale the iframe correctly for editor
        };

        this.sendPostMessage(data, iframeRef);
    }

    /**
     * Sends events RUN_PREVIEW and PLAY to a target iframe
     * Sets content in preview mode
     * @param iframeRef Target iframe
     */
    public runPreview(iframeRef: any): void {
        this.sendPostMessage({
            type: "RUN_PREVIEW",
        },
            iframeRef
        )
        setTimeout(() => {
            this.sendPostMessage({
                type: "PLAY"
            },
                iframeRef
            )
        }, 1000);
    }

    /**
     * Sends events EDIT_PREVIEW, PREVIEW_CONFIGURATION and PLAY to a target iframe
     * Sets content data (image, video, position, text) and prepares content for preview
     * @param iframeRef Target iframe
     * @param contentData content data properties (image, video, position, text)
     */
    public setPreviewConfiguration(iframeRef: any, contentData: any): void {
        this.sendPostMessage({
            type: "EDIT_PREVIEW",
        }, iframeRef
        );

        this.sendPostMessage({
            type: "PREVIEW_CONFIGURATION",
            properties: contentData
        },
            iframeRef
        );

        setTimeout(() => {
            this.sendPostMessage({
                type: "PLAY"
            },
                iframeRef
            )
        }, 1000)
    }

    /**
     * Send message via postMessage to a target iframe
     * @param message Message to send
     * @param iframeRef Target iframe
     */
    sendPostMessage(message: any, iframeRef: any): void {
        let iFrame = iframeRef
        let iframeWin = iFrame.current.contentWindow;
        if (iframeWin) {
            iframeWin.postMessage(message, "*");
        }
    }
}