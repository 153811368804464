import {injectable} from "tsyringe";
import { httpClient } from "../HttpClient";
import { ICampaignHttpService } from "./interfaces/ICampaignHttpService";
import { AxiosResponse } from "axios";
import {
    GetAllCampaignsParams,
    ICampaignsListResponse
} from "../../typings/apiModels/campaigns/CampaignModel";
import {ITemplatesListResponse} from "../../typings/apiModels/campaigns/TemplateModel";

@injectable()
export class CampaignHttpService implements ICampaignHttpService {

    getCampaignsAsync = async (params: GetAllCampaignsParams): Promise<AxiosResponse<ICampaignsListResponse>> => {
        return await httpClient.get(
            process.env.API_URL + 'mbc/campaigns/search?', {
                params: params
            });
    }

    getTemplatesListAsync = async (): Promise<AxiosResponse<ITemplatesListResponse>> => {
        return await httpClient.get(
            process.env.API_URL + 'mbc/templates'
        );
    }

}