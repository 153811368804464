// this is sample interface -> do not use it
export interface ICampaignsListResponse {
    totalNumberOfCampaigns: number;
    campaigns: IBaseCampaign[];
}

export interface IBaseCampaign {
    id: string;
    name: string;
    startDate: Date;
    endDate: Date;
    status: string;
    goals: ICampaignGoal[],
    type: string;
    creativeOrientation: string;
    departments: [];
    stage: string;
    thumbnail: string;
}

export interface ICampaignGoal {
    id: string;
    name: string;
    goalType: string;
}

export interface GetAllCampaignsParams {
    skip: number;
    take: number;
}

export interface CampaignDto {
    templateId?: string,
    name?: string,
    duration?: number,
    templateData?: any,
    templatePreviewUrl?: string
    inventories?: []
    startDate?: string,
    endDate?: string
    startTime?: string,
    endTime?: string
}

export const DefaultCampaignDto : { duration: number; name: string; templateData: {}; templateId: string; templatePreviewUrl: string } = {
    templateId: "",
    duration: 6,
    name: "",
    templateData: {},
    templatePreviewUrl: ""
};