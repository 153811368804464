import {CampaignType} from "../enum/CampaignType";
import {StageType} from "../enum/StageType";

export function checkIfCampaignEditIsEnabled(campaign) {
    return campaign.type && campaign.type === CampaignType.Local;
}

export function checkIfCampaignCancelIsEnabled(campaign) {
    return campaign.type && campaign.type.toLowerCase() !== CampaignType.Marketing &&
    campaign.stage !== StageType.Cancelled && campaign.stage !== StageType.Expired;
}