// React imports
import { useNavigate } from "react-router-dom";
// Logos
import logo from "../../../assets/images/logos/logo-primary.png";
// MUI Components imports
import {
  Drawer,
  List,
  ListItem,
} from "@mui/material";

import { MenuItems } from "./MenuItems";
import { IAuthorizationService } from "../../../utils/services/interfaces/IAuthorizationService";
import { AuthorizationService } from "../../../utils/services/AuthorizationService";
import { container } from "tsyringe";
import { IAuthUser } from "../../../typings/CommonTypes";

export const SideBar = () => {
  const authorizationService: IAuthorizationService = container.resolve(AuthorizationService);
  const currentUser: IAuthUser = authorizationService.getCurrentUser();

  function navigateToCampaigns(): void {
    navigate("/campaigns", { replace: true });
  }

  // Sets initial state of the sidebar from the context provider
  let navigate = useNavigate();

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      classes={{ paper: "sidebar-drawer" }}
    >
      <List
        className="top-content"
      >
        <ListItem className="logo-container">
          <img
            src={logo}
            onClick={navigateToCampaigns}
          />
        </ListItem>
      </List>
      <List className="main-nav">
        <MenuItems></MenuItems>
      </List>
    </Drawer>
  );
};
