import * as React from "react";
import { ListItemIcon, ListItemText, ListItemButton } from "@mui/material";

import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation } from "react-router-dom";

// Locale imports
import { useTranslation } from "react-i18next";
import { IAuthorizationService } from "../../../utils/services/interfaces/IAuthorizationService";
import { AuthorizationService } from "../../../utils/services/AuthorizationService";
import { container } from "tsyringe";
import { ILoggerService } from "../../../utils/services/interfaces/ILoggerService";
import { LogVerbosityLevel } from "../../../typings/enums/LogVerbosityLevelEnum";
import { ISideBarMenuItem } from "../../../typings/models/ISideBarMenuItem";
import {LoggerService} from "../../../utils/services/LoggerService";
import { useEffect, useState } from "react";

export const MenuItems = () => {
  const authorizationService: IAuthorizationService = container.resolve(AuthorizationService);
  const loggerService: ILoggerService = container.resolve(LoggerService);

  const [t] = useTranslation("locale");
  const { logout } = useAuth0();

  const [selectedIndex, setSelectedIndex] = useState<number>(1);

  const location = useLocation();
  const [menuItems, setMenuItems] = useState<ISideBarMenuItem[]>([]);

  useEffect(() => {
    var campaign : ISideBarMenuItem = {
      id: 1,
      text: "sidebar.campaigns",
      icon: <span className="material-symbols-rounded size-24">campaign</span>,
      path: '/campaigns',
      className: "menu-items-tabs",
      onClick: onNavigateToPageClick
    }
  
    var inventories : ISideBarMenuItem = {
      id: 2,
      text: "sidebar.inventories",
      icon: <span className="material-symbols-rounded size-24">all_inbox</span>,
      path: '/inventories',
      className: "menu-items-tabs",
      onClick: onNavigateToPageClick
    }
  
    var signOut : ISideBarMenuItem = {
      id: 3,
      text: "sidebar.signOut",
      icon: <span className="material-symbols-rounded size-24">logout</span>,
      path: '#',
      className: "menu-items-signout",
      onClick: handleLogout
    }
  
    menuItems.push(campaign, inventories, signOut);
    setMenuItems(menuItems);
  }, []);

  useEffect(() => {
    var locationStart = location.pathname.split("/")[1];
    const match = menuItems.find(item => item.path.includes(locationStart));
    if (match) setSelectedIndex(match.id);
  }, [location]);

  const onNavigateToPageClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
  };

  // Handles log out with auth0
  const handleLogout = () => {
    loggerService.logMessage(`User about to log off`, LogVerbosityLevel.Information);
    authorizationService.removeUserFromLocalStorage();
    logout({ logoutParams: { returnTo: window.location.origin } })  };

  return (
    <>
      {menuItems.map((item) => (
        <div key={item.id} id="sidebarLink" className="sidebar-link">
          {item.text ? (
            <Link to={item.path}>
              <ListItemButton
                selected={item.id === selectedIndex}
                key={item.id}
                className={item.className}
                onClick={(event) => {
                  item.onClick !== null && item.onClick(event, item.id);
                }}
              >
                <ListItemIcon color="inherit" className="sidebar-link-icon">
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={t(item.text)} className="sidebar-link-text" />
              </ListItemButton>
            </Link>
          ) : null}
        </div>
      ))}
    </>
  );
};
