import { container, singleton } from "tsyringe";
import { ILogElasticRequest } from "../../typings/CommonTypes";
import { LogVerbosityLevel } from "../../typings/enums/LogVerbosityLevelEnum";
import { AuthorizationService } from "./AuthorizationService";
import { IAuthorizationService } from "./interfaces/IAuthorizationService";
import { ILoggerService } from "./interfaces/ILoggerService";

@singleton()
export class LoggerService implements ILoggerService {
    private readonly authorizationService: IAuthorizationService = container.resolve(AuthorizationService);

    private readonly elasticSearchUrl: string = process.env.ELASTIC_SEARCH_URL || '';
    private readonly elasticSearchIndexPattern: string = process.env.ELASTIC_SEARCH_INDEX_PATTERN || '';
    

    public logMessage(message: string, verbosityLevel: LogVerbosityLevel = LogVerbosityLevel.Debug): void {

        if (verbosityLevel == LogVerbosityLevel.Debug) {
            return;
        }

        const currentUser = this.authorizationService.getCurrentUser();

        const bodyRequest: ILogElasticRequest = {
            level: LogVerbosityLevel[verbosityLevel],
            organizationId: currentUser.organizationId,
            userId: currentUser.userId,
            username: currentUser.email,
            message: message
        };


        fetch(this.elasticSearchUrl, {
            method: 'POST',
            body: JSON.stringify(bodyRequest),
            headers: {
                'Content-Type': 'application/json',
                "Application": this.elasticSearchIndexPattern
            }
        }).catch(function (ex) {
            console.log(`Error occurred while sending log. Error: ${ex}`);
        });
    }
}