import React, {useCallback, useEffect, useState} from 'react';
import {Box, Card, CardContent, Divider, FormControlLabel, Grid, Stack, Switch, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import DatePickerComponent from "../../../../common/DatePickerComponent";
import TimePickerComponent from "../../../../common/TimePickerComponent";
import {BuilderDto} from "../../../../../typings/models/campaignBuilder/BuilderDto";
import {CampaignDto} from "../../../../../typings/apiModels/campaigns/CampaignModel";

const ScheduleCreation = ({campaignDto, setCampaignDto, setBuilderDto}) => {

    const [t, i18n] = useTranslation('locale');
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [disabledTimeButtons, setDisableButtons] = useState<boolean>(
        (campaignDto.startTime == undefined &&
        campaignDto.endTime == undefined) ||
        (campaignDto.startTime === "00:00" &&
        campaignDto.endTime === "23:59")
    );
    // Use useEffect to update isNextButtonEnabled when date and times are selected
    useEffect(() => {
        if (selectedStartDate && selectedEndDate && (disabledTimeButtons || (startTime && endTime))) {
            setCampaignDto({
                ...campaignDto,
                startDate: selectedStartDate,
                endDate: selectedEndDate,
                startTime: startTime,
                endTime: endTime
            });

            const editedBuilderDto : BuilderDto = {
                isNextButtonClickable: true,
                setIsNextButtonClickable: true
            }
            setBuilderDto(editedBuilderDto);
        } else {
            const editedBuilderDto : BuilderDto = {
                isNextButtonClickable: false,
                setIsNextButtonClickable: false
            }
            setBuilderDto(editedBuilderDto);
        }
    }, [selectedStartDate, selectedEndDate, startTime, endTime]);

    function handleSwitch(event: React.ChangeEvent<HTMLInputElement>) {
        // Sets the time inputs enabled
        setDisableButtons(event.target.checked);
        if(event.target.checked) {
            setStartTime("00:00" as any);
            setEndTime("23:59" as any);
        }
    }

    return(
        <>
            <Box mt={2}>
                <Card variant="outlined">
                    <CardContent
                        sx={{padding: '0 !important'}}>
                        <Box p={2}>
                            <Typography variant="h6" fontSize={'16px'} fontWeight={'bold'}>{t('scheduleCreation.date')}</Typography>
                            <Typography variant="subtitle1">{t('scheduleCreation.scheduleDateTitle')}</Typography>
                        </Box>
                        <Divider variant="fullWidth"/>
                        <Stack direction={'row'} justifyContent={'left'} style={{height: 'calc(100vh - 620px)'}}>
                            <DatePickerComponent
                                startDate={campaignDto.startDate}
                                endDate={campaignDto.endDate}
                                onSelectStartDate={setSelectedStartDate}
                                onSelectEndDate={setSelectedEndDate} />
                        </Stack>
                    </CardContent>
                </Card>
            </Box>
            <Box mt={3} >
                <Card variant="outlined">
                    <CardContent sx={{padding: '0 !important'}}>
                        <CardContent>
                            <Typography variant="h6" fontSize={'16px'} fontWeight={'bold'}>{t('scheduleCreation.time')}</Typography>
                            <Typography variant="subtitle1">{t('scheduleCreation.scheduleTimeTitle')}</Typography>
                        </CardContent>
                        <Divider variant="fullWidth"/>
                        <Stack
                            direction={'row'}
                            justifyContent={'left'}
                            spacing={3} alignItems={'center'}
                        >
                            <Grid item container flex={1.5} justifyContent={'left'} p={2} className="switch-padding">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={handleSwitch}
                                            checked={disabledTimeButtons}
                                        />
                                    }
                                    label={t('scheduleCreation.timeSwitchLabel')}
                                />
                            </Grid>
                            <Divider orientation="vertical" flexItem/>
                            <Grid item flex={2} p={2} >
                                <TimePickerComponent label={t('scheduleCreation.startTimeLabel')}
                                                     disabled={disabledTimeButtons}
                                                     selectedTime={campaignDto.startTime}
                                                     onSelectTime={setStartTime}
                                />
                            </Grid>
                            <Grid item flex={2} p={2}>
                                <TimePickerComponent label={t('scheduleCreation.endTimeLabel')}
                                                     disabled={disabledTimeButtons}
                                                     selectedTime={campaignDto.endTime}
                                                     onSelectTime={setEndTime}
                                />
                            </Grid>
                        </Stack>
                    </CardContent>
                </Card>
            </Box>
        </>
    )
};

export default ScheduleCreation;