import { useRef, useEffect, useState } from 'react'

import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { container } from 'tsyringe';
import { MediaEventService } from '../../../../../../utils/services/MediaEventService';
import { IMediaEventService } from '../../../../../../utils/services/interfaces/IMediaEventService';
import {TemplateOrientations} from "../../../enum/templates/TemplateOrientations";

export function CreativePreview({ templatePreviewUrl, templateData, runPreviewRequested, handleClose }) {
    const mediaEventService: IMediaEventService = container.resolve(MediaEventService);

    // Gets the iframe ref
    let iframeRef = useRef<HTMLIFrameElement | null>(null);

    const [iframeWidth, setIframeWidth] = useState<number | string>(480)
    const [iframeHeight, setIframeHeight] = useState<number | string>(850)

    // Removes the scroll functionality when preview is open
    useEffect(() => {
        document.body.style.overflow = 'hidden'

        iframeRef.current?.addEventListener('load', () => {
            if (runPreviewRequested) {
                mediaEventService.runPreview(iframeRef);
            }
        })

        return () => { document.body.style.overflow = '' }
    }, [templatePreviewUrl])


    useEffect(() => {
        if (templateData) {
            if (templateData.orientation == TemplateOrientations.portrait) {
                setIframeWidth('auto');
                setIframeHeight('95%');
            }
            else {
                setIframeWidth('90%');
                setIframeHeight('95%');
            }

            mediaEventService.setPreviewConfiguration(iframeRef, templateData);
        }
    }, [templateData])

    function onclose() {
        handleClose();
    }

    return (
        <div className="previewContainer">
            <iframe
                loading='lazy'
                ref={iframeRef}
                src={templatePreviewUrl}
                id="singleframeid"
                frameBorder="0"
                width={iframeWidth}
                height={iframeHeight}
            >
            </iframe>

            <div className="closeBtn">
                <IconButton size={'large'} onClick={onclose}>
                    <CloseOutlinedIcon />
                </IconButton>
            </div>
        </div>
    )
}