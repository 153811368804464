// MUI components imports
import { AppBar, Typography } from "@mui/material";
export const Footer = () => {
    return (
        <>
            <AppBar
                color="inherit"
                className="footer">
                <div>
                    <Typography  className={'footer-typography'}>
                        &copy; 2022 LDSK
                    </Typography>
                </div>
            </AppBar>
        </>
    )
}