import {TemplateOrientations} from "../modules/views/campaigns/enum/templates/TemplateOrientations";

export default class TemplateUtils {

    static getTemplateRatioFromTags(tags: string[]): string {
        let ratio: string = '16:9';

        if (tags && tags.length > 0) {
            tags.forEach(currentTag => {
                switch (currentTag) {
                    case 'ratio_16:9':
                        ratio = '16:9';
                        break;
                    case 'ratio_9:16':
                        ratio = '9:16';
                        break;
                    case 'ratio_32:9':
                        ratio = '32:9';
                        break;
                }
            });
        }

        return ratio;
    }

    static getTemplateOrientation(tags: string[]): string {
        let orientation: string = 'landscape';

        if (tags && tags.length > 0) {
            tags.forEach(currentTag => {
                switch (currentTag) {
                    case TemplateOrientations.portrait:
                        orientation = TemplateOrientations.portrait;
                        break;
                    case TemplateOrientations.landscape:
                        orientation = TemplateOrientations.landscape;
                        break;
                }
            });
        }

        return orientation;
    }

    static calculateTemplateIconWidthAndHeightByRatio(tags: string[]): {} {
        let width: string = '266px';
        let height: string = '266px';

        const aspectRatio = TemplateUtils.getTemplateRatioFromTags(tags);

        switch (aspectRatio) {
            case 'ratio_16:9':
                width = "266px";
                height = "150px";
                break;
            case 'ratio_9:16':
                width = "150px";
                height = "266px";
                break;
            case 'ratio_32:9':
                width = "266px";
                height = "75px";
                break;
        }

        return {width, height};
    }
}