// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datagrid-container {
  height: 73vh;
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks in cells */
}

.datagrid-toolbar-button {
  padding: 5px 15px;
  background-color: transparent !important;
  border: none !important;
}

.datagrid-toolbar-button:hover {
  background-color: transparent !important;
  border: none !important;
}

.menu-item {
  margin: 1.5px;
}

.menu-item-cancel {
  background-color: rgba(242, 121, 104, 0.5) !important;
}

.menu-item-icon {
  margin-right: 12px;
}

.previewContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow: scroll;
  z-index: 3000;
}
.previewContainer .closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.actions-menu {
  padding: 8px !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/layout/campaign/Campaigns.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,WAAA;EACA,gBAAA,EAAA,gCAAA;EACA,mBAAA,EAAA,iCAAA;AADJ;;AAIA;EACI,iBAAA;EACA,wCAAA;EACA,uBAAA;AADJ;;AAIA;EACI,wCAAA;EACA,uBAAA;AADJ;;AAIA;EACI,aAAA;AADJ;;AAIA;EACI,qDAAA;AADJ;;AAIA;EACI,kBAAA;AADJ;;AAIA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,gBAAA;EACA,aAAA;AADJ;AAGI;EACI,kBAAA;EACA,SAAA;EACA,WAAA;AADR;;AAKA;EACI,uBAAA;AAFJ","sourcesContent":["@import '../../theme/variables';\n\n.datagrid-container {\n    height: 73vh;\n    width: 100%;\n    overflow-x: auto; /* Enable horizontal scrolling */\n    white-space: nowrap; /* Prevent line breaks in cells */\n}\n\n.datagrid-toolbar-button {\n    padding: 5px 15px;\n    background-color: transparent !important;\n    border: none !important;\n}\n\n.datagrid-toolbar-button:hover {\n    background-color: transparent !important;\n    border: none !important;\n}\n\n.menu-item {\n    margin: 1.5px;\n}\n\n.menu-item-cancel {\n    background-color: $color-error-states-outlined-resting-border !important;\n}\n\n.menu-item-icon {\n    margin-right: 12px;\n}\n\n.previewContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    background-color: #fff;\n    overflow: scroll;\n    z-index: 3000;\n\n    .closeBtn {\n        position: absolute;\n        top: 10px;\n        right: 10px;\n    }\n}\n\n.actions-menu {\n    padding: 8px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
