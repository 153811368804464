import '../../../styles/layout/campaign/Campaigns.scss';
import { Button, TextField, InputAdornment, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

const DataGridToolbar = () => {
    const [t] = useTranslation('locale');

    return (
        <Grid container sx={{p: 2}}>
            <Grid item xs={10}>
                <TextField
                    fullWidth 
                    size="small"
                    placeholder="Search by name"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={2}>
                <Grid container spacing={1} justifyContent="right">
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            startIcon={<span className="material-symbols-outlined">upload</span>}
                            className='datagrid-toolbar-button'
                        >
                            {t('campaigns.toolbar.export')}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            startIcon={<span className="material-symbols-outlined">filter_list</span>}
                            className='datagrid-toolbar-button'
                        >
                            {t('campaigns.toolbar.filters')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DataGridToolbar;
