// React component imports
import { UserAccountMenu } from "./components/UserAccountMenu";
import React, { useState, useContext } from "react";

// MUI Components imports
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
} from "@mui/material";

import { useAuth0 } from "@auth0/auth0-react";

export const TopBar = () => {

  const { user } = useAuth0();

  // Set to open drop down menu for user settings
  const [userMenu, setUserMenu] = useState<null | HTMLElement>(null);

  // Handles user drop down menu
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenu(event.currentTarget);
  };

  return (
      <>
        <AppBar
            component="nav"
            position="sticky"
            elevation={0}
            color="inherit"
            className="header"
        >
          <Toolbar className="header-content"
          >
            <Box className="header-actions">

              <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="profile"
                  onClick={handleOpen}
              >
                <span className="material-symbols-rounded size-24 header-icon" >person</span>
              </IconButton>
              <UserAccountMenu
                  name={user?.name}
                  email={user?.email}
                  setUserMenu={setUserMenu}
                  userMenu={userMenu}
              />
            </Box>
          </Toolbar>
        </AppBar>
      </>
  );
};
