import {defaultCustomDialogParams} from "../../../typings/CommonTypes";
import {buildThumbnail} from "../../common/BuildThumbnail";

interface CampaignsGridViewComponentProps {
    showCampaignsGrid: boolean;
}

import '../../../styles/layout/campaign/Campaigns.scss';
import {
    DataGridPro,
    DataGridProProps,
    GridColDef,
    GridPaginationModel,
    LicenseInfo,
    NoRowsOverlayPropsOverrides
} from '@mui/x-data-grid-pro';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {container} from 'tsyringe';
import {AxiosResponse} from 'axios';
import {GetAllCampaignsParams, ICampaignsListResponse} from '../../../typings/apiModels/campaigns/CampaignModel';
import {Chip, Tooltip, Typography, useMediaQuery} from '@mui/material';
//services
import {ICampaignHttpService} from '../../../utils/services/interfaces/ICampaignHttpService';
import {CampaignHttpService} from '../../../utils/services/CampaignHttpService';
//utils
import {ILoggerService} from '../../../utils/services/interfaces/ILoggerService';
import {LoggerService} from '../../../utils/services/LoggerService';
import {ILdskHttpError} from '../../../typings/apiModels/CommonApiModel';
import {LogVerbosityLevel} from '../../../typings/enums/LogVerbosityLevelEnum';
//icons
import CustomToolbar from './DataGridToolbar';
import {extractHoursAndMinutesForCampaignView, formatDateTimeForCampaignView} from "../../common/DateTimeConverter";
import getCampaignStageChipProperties from "./components/CampaignsStageChipProperties";
import {getCampaignTypeChipProperties} from "./components/CampaignsTypeChipProperties";
import NoData from "../../common/NoData";
import {ActionsMenu} from "../../common/ActionsMenu";
import {CustomDialog} from "../../common/CustomDialog";

export const CampaignsGridList = ({ showCampaignsGrid }: CampaignsGridViewComponentProps) => {
    const campaignHttpService: ICampaignHttpService = container.resolve(CampaignHttpService);
    const loggerService: ILoggerService = container.resolve(LoggerService);

    const [t, i18n] = useTranslation('locale');
    const [isLoading, setIsLoading] = useState(true);
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const [campaigns, setCampaigns] = useState<DataGridProProps["rows"]>([]);
    const [totalCampaigns, setTotalCampaigns] = useState(0);

    const noDataFromSearchProps: NoRowsOverlayPropsOverrides = {
        showIcon: "search_off",
        title: t('campaigns.listLoad.emptySearch.title'),
        description: t('campaigns.listLoad.emptySearch.subTitle')
    };

    const [currentNoDataProps, setCurrentNoDataProps] = useState(noDataFromSearchProps);

    const [customDialogParams, setCustomDialogParams] = useState(defaultCustomDialogParams);
    const [customDialogOpen, setCustomDialogOpen] = useState(false);

    const handlePaginationModelChange = (newModel: GridPaginationModel) => {
        setPaginationModel(newModel);
    };

    function goToCampaignDetails(campaignId: string) {
        window.location.assign(`/#/campaigns`)
    }

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t("campaigns.fields.name") as string,
            flex: 4,
            renderCell: ({ row }) => {
                const elementThumbnail = buildThumbnail(row.thumbnail, row.creativeOrientation);

                return (
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', maxWidth: '100%', cursor: 'pointer' }}>
                            {elementThumbnail}
                            {row.name.length > 40 ? (
                                <Tooltip onClick={()=> goToCampaignDetails(row.id)} title={row.name} placement="top">
                                    <span className="campaignName">{row.name}</span>
                                </Tooltip>
                            ) : (
                                <span>{row.name}</span>
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            field: 'type',
            headerName: t("campaigns.fields.type") as string,
            flex: 1,
            renderCell: ({row}) => {
                const {backgroundColor, textColor} = getCampaignTypeChipProperties(row.type);
                let statusLabel = t("campaigns.type." + row.type.toLowerCase())
                return <Chip
                    label={statusLabel}
                    size="small"
                    className='type-label'
                    style={{backgroundColor: backgroundColor, color: textColor}}
                />
            }
        },
        {
            field: 'display',
            headerName: t("campaigns.fields.display") as string,
            flex: 1,
            renderCell: ({row}) => {
                return row.departments?.length > 1
                    ? `${row.departments.length} ${t('campaigns.fields.departments')}`
                    : row.departments?.length === 1
                        ? row.departments[0]
                        : '';
            }
        },
        {
            field: 'stage',
            headerName: t("campaigns.fields.stage") as string,
            flex: 1,
            renderCell: ({row}) => {
                const {backgroundColor, textColor} = getCampaignStageChipProperties(row.stage);
                let statusLabel = t("campaigns.stage." + row.stage.toLowerCase())
                return <Chip
                    label={statusLabel}
                    size="small"
                    className='stage-label'
                    style={{backgroundColor: backgroundColor, color: textColor}}
                />
            }
        },
        {
            field: 'startEndDate',
            headerName: t("campaigns.fields.startEndDate") as string,
            flex: 1,
            renderCell: ({row}) =>
            {
                return <div>
                    <div>{formatDateTimeForCampaignView(row.startDate)}</div>
                    <div>{formatDateTimeForCampaignView(row.endDate)}</div>
                </div>
            }
        },
        {
            field: 'time',
            headerName: t("campaigns.fields.time") as string,
            flex: 1,
            renderCell: ({row}) =>
            {
                return <div>
                    <div>{extractHoursAndMinutesForCampaignView(row.startDate)}</div>
                    <div>{extractHoursAndMinutesForCampaignView(row.endDate)}</div>
                </div>
            }
        },
        {
            field: 'actions',
            headerName: '',
            flex: 1,
            maxWidth: 50,
            align: "right",
            renderCell: ({row}) =>
            {
                return <ActionsMenu campaign={row} setCustomDialogParams={setCustomDialogParams}
                                    setCustomDialogOpen={setCustomDialogOpen}/>
            }
        }
    ];

    useEffect(() => {
        (async () => {
                await getCampaigns();
            }
        )();
    }, [paginationModel]);

    const getCampaigns = async () => {
        setIsLoading(true);
        const params: GetAllCampaignsParams = {
            skip: paginationModel.page + 1,
            take: paginationModel.pageSize,
        };

        await campaignHttpService.getCampaignsAsync(params)
            .then(async (response:AxiosResponse<ICampaignsListResponse>) => {
                setCampaigns(response?.data.campaigns || []);
                setTotalCampaigns(response?.data.totalNumberOfCampaigns ?? 0);
            })
            .catch((errorResponse: ILdskHttpError) => {
                console.log(errorResponse);
                loggerService.logMessage(`Error fetching campaigns ${errorResponse.message}`, LogVerbosityLevel.Error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            {showCampaignsGrid &&
                <div className='datagrid-container'>
                    {campaigns.length > 0 || columns.length > 0 ?
                        <DataGridPro
                            sx={{ border: 0 }}
                            loading={isLoading}
                            rowCount={totalCampaigns}
                            rows={campaigns}
                            columns={columns}
                            rowHeight={isSmallScreen ? 60 : 88}
                            getRowId={(row) => row?.id}
                            pageSizeOptions={[10, 25, 50, 100]}
                            pagination
                            paginationMode='server'
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            isRowSelectable={(params) => false}
                            disableColumnPinning={true}
                            disableColumnMenu={true}
                            slots={{
                                toolbar: CustomToolbar,
                                noRowsOverlay: NoData,
                                noResultsOverlay: NoData
                            }}
                            slotProps={{
                                toolbar: {},
                                noRowsOverlay: currentNoDataProps,
                                noResultsOverlay: currentNoDataProps
                            }}
                        /> :
                        !isLoading && <div style={{ width: '100%' }}>
                            <Typography>There is no data!</Typography>
                        </div>
                    }
                    <CustomDialog customDialogParams={customDialogParams} setCustomDialogParams={setCustomDialogParams}
                                  customDialogOpen={customDialogOpen}  setCustomDialogOpen={setCustomDialogOpen}/>
                </div>
            }
        </>
    );
};
