import { injectable } from "tsyringe";
import { IInventoryHttpService } from "./interfaces/IInventoryHttpService";
import { httpClient } from '../HttpClient';
import { IRequestQueryControl } from "../../typings/CommonTypes";
import { IDisplaysListResponse } from "../../typings/apiModels/campaigns/DisplayModel";


@injectable()
class InventoryHttpService implements IInventoryHttpService {

    getAllInventoriesAsync = async () => {

        let inventoriesRequest = {
            "advancedFilter": {
                // filterItems: filterItems,
                "includePreselectedInvs": false,
                "preselectedInventoryIds": [
                ],
                "useAdvancedFilters": false
            },
            "filter": [
                "name",
                "type",
                "templateName",
                "ScreenCount",
                "status",
                "orientation",
                "CalculatedProperties",
                "playGroupId"
            ],
            "includePlaygroupAttributes": true,
            "includeScheduledCampaignsCount": true,
            "includeTotalCampaignsCount": true,
            "query": [
                "properties.name"
            ],
            "queryvalue": [
                "/(?i)/g"
            ],
            "skip": 0,
            "sort": "properties.name",
            "sortasc": true,
            "take": 20,
            "types": [
                "Inventory",
                "PlayGroup"
            ]
        };

        return await httpClient.post<IDisplaysListResponse>('/v2/inventory/list/store-dms', inventoriesRequest);
    };

}
export default InventoryHttpService;