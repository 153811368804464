// React components imports
import { SideBar } from "./layout/sideBar/SideBar";
import { TopBar } from "./layout/topBar/TopBar";
import { Footer } from "./layout/footer/Footer";
import { ContentRoutes } from "./routes/ContentRoutes";
import { useState } from "react";

//MUI styles imports
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme/theme";

// Contexts imports
import { sidebarContext } from "../contexts/SidebarContext";
import { ILayoutProps } from "../typings/CommonTypes";
import { Divider } from "@mui/material";

export const Layout = ({}: ILayoutProps) => {
  // Gets the local storage of the sidebar visibility status
  let sidebarOpenStatus = localStorage.getItem("sidebar");
  // use states created for props to the context provider
  const [sidebarVisibility, setSidebarVisibility] = useState(sidebarOpenStatus);
  const [iconRotation, setIconRotation] = useState("");

  return (
    <ThemeProvider theme={theme}>
      <div className="content-wrapper">
        <sidebarContext.Provider value={{ sidebarVisibility }}>
          <SideBar />
        </sidebarContext.Provider>
        <div className="content">
          <sidebarContext.Provider
            value={{ setSidebarVisibility, iconRotation, setIconRotation }}
          >
            <TopBar />
            <Divider />
          </sidebarContext.Provider>
          <div className="main-content">
            <ContentRoutes />
          </div>
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  );
};
