// React router imports
import { Routes, Route, Navigate } from "react-router-dom";
import { CampaignsView } from "../views/campaigns/CampaignsView";
import { InventoriesView } from "../views/inventories/InventoriesView";
import { useTranslation } from "react-i18next";
import ProtectedRoute from "./ProtectedRoute";
import { ExpiredToken } from "../views/errorPages/ExpiredToken";
import {CampaignBuilder} from "../views/campaigns/campaignBuilder/CampaignBuilder";

export const ContentRoutes = ()=> {
    const [t] = useTranslation('locale');

    return(
        <Routes>
            <Route path='/' element={<ProtectedRoute />}>
                <Route index element={<Navigate to="/campaigns" replace={true} />} />
            </Route>
            <Route path='/campaigns' element={<ProtectedRoute />}>
                <Route index element={<CampaignsView />} />
            </Route>
            <Route path='/inventories' element={<ProtectedRoute />}>
                <Route index element={<InventoriesView />} />
            </Route>
            <Route path="/create" element={<ProtectedRoute />}>
                <Route index element={<CampaignBuilder />} />
            </Route>

            {/* expired token */}
            <Route path='/expired-token' element={<ProtectedRoute />}>
                <Route index element={<ExpiredToken />} />
            </Route>
        </Routes>
    )
}