import {LocalizationProvider, TimePicker} from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import React from "react";
import dayjs, {Dayjs} from "dayjs";
import {useTranslation} from "react-i18next";

export const TimePickerComponent = ({selectedTime, onSelectTime, label, disabled})=> {

    const [t, i18n] = useTranslation('locale');
    const [value, setValue] = React.useState<Dayjs | null>(selectedTime || dayjs('T00:00'));

    const handleTimeChange = (newValue) => {
        onSelectTime(newValue); // Pass the selected time to the parent component
    };

    return(
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
                label={label}
                onChange={(newValue) => handleTimeChange(newValue)}
                views={['hours', 'minutes']} // Show only hours and minutes
                minutesStep={15} // Divide minutes in 15-minute intervals
                sx={{width: "100%"}}
                value={value}
                disabled={disabled}
            />
        </LocalizationProvider>
    );
}

export default TimePickerComponent