import React, {useState} from "react";
import {Card, CardContent, Chip, Divider, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import TemplateUtils from "../../../../../../utils/TemplateUtils";
import {BuilderDto} from "../../../../../../typings/models/campaignBuilder/BuilderDto";
import {CampaignDto} from "../../../../../../typings/apiModels/campaigns/CampaignModel";
import '/src/styles/layout/campaign/campaignBuilder/TemplateSelector.scss';
import {AddCircleOutlineOutlined, CheckCircle, RemoveRedEyeOutlined} from "@mui/icons-material";
import theme from "../../../../../../theme/theme";
import {useTranslation} from "react-i18next";
import { CreativePreview } from "../creativeEditor/CreativePreview";

const TemplatesGridView = ({templates, setCampaignDto, setBuilderDto}) => {

    const [t, i18n] = useTranslation('locale');
    const [hoveredTemplate, setHoveredTemplate] = useState(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
    const [preview, setPreview] = useState<boolean>(false);

    const handleSelect = (templateId: string, previewUrl: string) => {
        setSelectedTemplates((prevSelected) => [...prevSelected, templateId]);

        if (previewUrl) {
            previewUrl = previewUrl.replace('ldsk-player-simulator', 'index');
        }

        const editedCampaignDto : CampaignDto = {
            templateId: templateId,
            templatePreviewUrl: previewUrl
        }
        setCampaignDto(editedCampaignDto);
        const editedBuilderDto : BuilderDto = {
            isNextButtonClickable: true,
            setIsNextButtonClickable: true
        }
        setBuilderDto(editedBuilderDto)
    };

    const handlePreview = (templateUrl: string) => {
        setPreviewUrl(templateUrl);
        setPreview(true);
    };

    return (
        <>
            {
                preview && <CreativePreview templatePreviewUrl={previewUrl} templateData={{}} runPreviewRequested={preview} handleClose={() => setPreview(false)} />
            }
            <Grid container spacing={3} className="templates-grid-view">
                {templates.map((template) => {
                    const { calculatedWidth, calculatedHeight } = TemplateUtils.calculateTemplateIconWidthAndHeightByRatio(template.tags);
                    const hoveredOrSelected = hoveredTemplate === template.id || selectedTemplates[0] === template.id;
                    return (
                        <Grid item key={template.id} xs={12} sm={6} md={3}>
                            <Card
                                onMouseOver={() => setHoveredTemplate(template.id)}
                                onMouseLeave={() => setHoveredTemplate(null)}
                                style={{ height: '360px' }}
                            >
                                <div className="card-media-wrapper" style={{ backgroundColor: hoveredOrSelected ?
                                        theme.palette.other.backdropOverlay : theme.palette.primary.contrastText }}>
                                    <img
                                        src={template.thumbnail.url}
                                        alt={template.displayName}
                                        style={{
                                            width: calculatedWidth,
                                            height: calculatedHeight,
                                            filter: hoveredOrSelected? "brightness(1.2)" : "none"
                                        }}
                                    />
                                    {hoveredTemplate === template.id && (
                                        <div className="card-media-icons-wrapper">
                                            <div className="icon-preview-wrapper">
                                                <IconButton className="icon-preview" onClick={() => handlePreview(template.previewUrl)}>
                                                    <RemoveRedEyeOutlined />
                                                </IconButton>
                                                <Typography className="icon-text" variant="caption" display="block">{t('templates.icons.preview')}</Typography>
                                            </div>
                                            <div className="icon-plus-circle-wrapper">
                                                <IconButton  className="icon-plus-circle" onClick={() => handleSelect(template.id, template.previewUrl)}>
                                                    <AddCircleOutlineOutlined />
                                                </IconButton>
                                                <Typography className="icon-text" variant="caption" display="block" noWrap>{t('templates.icons.select')}</Typography>
                                            </div>
                                        </div>
                                    )}
                                    {selectedTemplates[0] === template.id && (
                                        <div className="check-icon-wrapper">
                                            <CheckCircle className="check-icon-selected" />
                                        </div>
                                    )}
                                </div>
                                <Divider />
                                <CardContent className="card-content-wrapper">
                                    {template.displayName.length > 20 ? (
                                        <Tooltip title={template.displayName}>
                                            <Typography variant="body2" noWrap>
                                                {template.displayName}
                                            </Typography>
                                        </Tooltip>
                                    ) : (
                                        <Typography variant="body2" noWrap>
                                            {template.displayName}
                                        </Typography>
                                    )}
                                    <Chip label={TemplateUtils.getTemplateRatioFromTags(template.tags)} size="small"/>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

export default TemplatesGridView;