import { AuthorizationType } from "./enums/AuthorizationTypeEnum";
import { AlertColor } from '@mui/material';
import React from "react";

export interface KeyValuePair {
    key: string;
    value: string;
}

export type createCampaignParams = {
    templateId: string
    previewUrl: string
    templateOrientation: string
    tags: string[]
}

export interface IDisplaySummaryViewListProps {
    selectedDisplays: any[];
}

export interface ILivedoohApiErrorResponse {
    statusCode: number;
    errorMessage: string;
    statusText: string,
}

export interface IRequestQueryControl {
    skip: number;
    take: number;
    sort: string,
    sortasc: boolean;
}

// other properties to be included when required
export interface IAuthUser {
    accessToken: string;
    expiresAt: number;
    email: string;
    userId: string;
    authorizationType: AuthorizationType;
    organizationId: number;
    firstName: string;
    lastName: string;
    currencySymbol: string;

    userGroups: string[];
    permissions: string[];
    roles: string[];
}


export interface IUserInfoResponse {
    id: string;
    permissions: string[];
    roles: string[];
    firstName: string;
    lastName: string;
    currencySymbol: string;
}

export interface ILayoutProps {
}

export interface IUserAccountMenu {
    name: string | undefined,
    email: string | undefined,
    userMenu: any,
    setUserMenu: any,
}

export interface ILogElasticRequest {
    level: string;
    organizationId?: number;
    userId: string;
    username: string;
    message: string;
}

export interface CustomAlertProps{
    severity: AlertColor | undefined;
    title: string;
    message: string;
    style?: object | undefined;
};

export interface PageHeaderComponentProps {
    headerTitle: string,
    headerSubtitle?: string | undefined | null,
    headerButton?: PageHeaderButtonProps | undefined | null,
    headerDiscardButton?: PageHeaderButtonProps | undefined | null,
    headerButtonDisabled?: boolean | undefined | null,
}

export interface PageHeaderButtonProps {
    name: string,  
    icon: string,
    tooltip?: string,
    onClicked: () => void,
    style?: React.CSSProperties
}

export interface CustomDialogParams {
    title: string;
    description: string;
    onConfirm: () => void
    buttonCancelText?: string;
    buttonConfirmText?: string;
    shouldRedirect?: boolean;
    redirectUrl?:string;
}

export const defaultCustomDialogParams : CustomDialogParams = {
    title: "",
    description: "",
    onConfirm: () => void {}
};
