// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.display-grid-list-container {
  height: calc(100vh - 384px);
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks in cells */
}`, "",{"version":3,"sources":["webpack://./src/styles/layout/campaign/campaignBuilder/DisplaySelector.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,WAAA;EACA,gBAAA,EAAA,gCAAA;EACA,mBAAA,EAAA,iCAAA;AACF","sourcesContent":[".display-grid-list-container {\n  height: calc(100vh - 384px);\n  width: 100%;\n  overflow-x: auto; /* Enable horizontal scrolling */\n  white-space: nowrap; /* Prevent line breaks in cells */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
